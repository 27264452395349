<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Editar funcionário</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="fname">Nome:</label>
                                    <input 
                                        type="text" 
                                        v-model="employeeDetail.Nome" 
                                        class="form-control"
                                        :class="`form-control ${$v.employeeDetail.Nome.$error ? 'is-invalid' : ''}`" 
                                        id="name" 
                                        placeholder="Nome"
                                        @input="$v.employeeDetail.Nome.$touch()" 
                                        @blur="$v.employeeDetail.Nome.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="lname">Cargo:</label>
                                    <input 
                                        type="text" 
                                        v-model="employeeDetail.Cargo" 
                                        class="form-control" 
                                        :class="`form-control ${$v.employeeDetail.Cargo.$error ? 'is-invalid' : ''}`" 
                                        id="role" 
                                        placeholder="Cargo"
                                        @input="$v.employeeDetail.Cargo.$touch()" 
                                        @blur="$v.employeeDetail.Cargo.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="add1">Email:</label>
                                    <input 
                                        type="email" 
                                        v-model="employeeDetail.Email" 
                                        class="floating-input form-control" 
                                        :class="`form-control ${$v.employeeDetail.Email.$error ? 'is-invalid' : ''}`" 
                                        placeholder="Email" 
                                        @input="$v.employeeDetail.Email.$touch()" 
                                        @blur="$v.employeeDetail.Email.$touch()"
                                    >
                                </div>
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Atualizar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
    name:'EmployeeEdit',
    mixins: [validationMixin],
    validations: {
        employeeDetail: {
            Nome: {required},
            Cargo: { required },
            Email:{ required , email }
        }
    },
    data() {
        return {
            employeeDetail: {},
            employees: JSON.parse(localStorage.getItem('employees'))
        }
    },
    mounted () {
        if(this.$route.params.employee) {
            this.employeeDetail = this.$route.params.employee;
        }
        else if(this.$route.query.id && !this.$route.params.employee) {
            this.employeeDetail = this.employees[this.$route.query.id];
        }
        else{
            this.$router.push({name:'app.employees-list'});
        }
    },
    methods:{
        onSubmit()
        {
            let employees = JSON.parse(localStorage.getItem('employees'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                for(let index in employees) {
                    if(employees[index].id === this.employeeDetail.id) {
                        employees[index] = {Nome: this.employeeDetail.Nome, Cargo: this.employeeDetail.Cargo, Email: this.employeeDetail.Email}
                        localStorage.setItem('employees', JSON.stringify(employees));
                        break;
                    }
                }
                this.$router.push({name:'app.employees-list'})
            }
        }
    }
}
</script>